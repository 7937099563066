<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Encuestas Conductores y Pasajeros</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Preguntas Encuestas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="row m-4">
                <div class="col-md-12">
                  <div v-drag-and-drop:options="options">
                    <ul class="drag-list">
                      <li class="drag-column">
                        <span class="drag-column-header">
                          <strong><h3>Encuesta Conductor</h3></strong>
                          <VueFeather
                            style="cursor: pointer; color: navy"
                            @click="addQuestionConductor()"
                            type="plus-circle"
                          ></VueFeather>
                        </span>
                        <vue-draggable-group
                          v-model="groups[0].items"
                          :groups="groups[0].items"
                          :data-id="groups[0].id"
                          @change="changeConductores"
                        >
                          <ul class="drag-inner-list">
                            <li
                              class="drag-item"
                              v-for="(item, indice1) in groups[0].items"
                              :key="item.id"
                              :data-id="item.id"
                            >
                              <div class="drag-item-text">
                                <div class="row">
                                  <div class="col-md-11">
                                    <textarea
                                      v-model="
                                        groups[0].items[indice1].pregunta
                                      "
                                      style="resize: none"
                                      cols="30"
                                      rows="4"
                                      class="
                                        form-control form-control-sm
                                        p-0
                                        ml-2
                                        m-1
                                      "
                                    ></textarea>
                                  </div>
                                  <div class="col-md-1">
                                    <input
                                      type="checkbox"
                                      v-model="groups[0].items[indice1].check"
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </vue-draggable-group>
                        <div
                          class="card-footer"
                          v-if="groups[0].items.length > 0"
                        >
                          <div class="row">
                            <div class="col-md-6"></div>
                            <button
                              type="button"
                              class="btn bg-light m-1 align-top"
                              style="cursor: pointer"
                              @click="actConductores"
                            >
                              <i
                                style="font-size: 22px"
                                class="fas fa-sync"
                              ></i>
                            </button>
                            <button
                              style="color: white; cursor: pointer"
                              class="btn btn-sm bg-navy m-1"
                              @click="save(1)"
                            >
                              <i class="fas fa-check-circle"></i><br /><strong
                                >Guardar
                              </strong>
                            </button>
                            <button
                              style="color: white; cursor: pointer"
                              class="btn btn-sm btn-dark m-1"
                              @click="limpiarConductores()"
                            >
                              <i class="fas fa-trash"></i><br /><strong
                                >Limpiar
                              </strong>
                            </button>
                          </div>
                        </div>
                      </li>
                      <li class="drag-column">
                        <span class="drag-column-header">
                          <h3>Encuesta Pasajero</h3>
                          <VueFeather
                            style="cursor: pointer; color: navy"
                            @click="addQuestionPasajero()"
                            type="plus-circle"
                          ></VueFeather>
                        </span>
                        <vue-draggable-group
                          v-model="groups[1].items"
                          :groups="groups[1].items"
                          :data-id="groups[1].id"
                          @change="changePasajeros"
                        >
                          <ul class="drag-inner-list">
                            <li
                              class="drag-item"
                              v-for="(item, indice2) in groups[1].items"
                              :key="item.id"
                              :data-id="item.id"
                            >
                              <div class="drag-item-text">
                                <div class="row">
                                  <div class="col-md-11">
                                    <textarea
                                      v-model="
                                        groups[1].items[indice2].pregunta
                                      "
                                      style="resize: none"
                                      cols="30"
                                      rows="4"
                                      class="
                                        form-control form-control-sm
                                        p-0
                                        ml-2
                                        m-1
                                      "
                                    ></textarea>
                                  </div>
                                  <div class="col-md-1">
                                    <input
                                      type="checkbox"
                                      v-model="groups[1].items[indice2].check"
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </vue-draggable-group>
                        <div
                          class="card-footer"
                          v-if="groups[1].items.length > 0"
                        >
                          <div class="row">
                            <div class="col-md-6"></div>
                            <button
                              type="button"
                              class="btn bg-light m-1 align-top"
                              style="cursor: pointer"
                              @click="actPasajeros()"
                            >
                              <i
                                style="font-size: 22px"
                                class="fas fa-sync"
                              ></i>
                            </button>
                            <button
                              style="color: white; cursor: pointer"
                              class="btn btn-sm bg-navy m-1"
                              @click="save(2)"
                            >
                              <i class="fas fa-check-circle"></i><br /><strong
                                >Guardar
                              </strong>
                            </button>
                            <button
                              style="color: white; cursor: pointer"
                              class="btn btn-sm btn-dark m-1"
                              @click="limpiarPasajeros()"
                            >
                              <i class="fas fa-trash"></i><br /><strong
                                >Limpiar
                              </strong>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-footer"></div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import VueFeather from "vue-feather";

export default {
  name: "PreguntasEncuestasIndex",
  components: {
    Loading,
    VueFeather,
  },
  data() {
    return {
      cargando: false,
      groups: [
        {
          id: 1,
          items: [],
        },
        {
          id: 2,
          items: [],
        },
      ],
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
      },
    };
  },

  methods: {
    getIndex() {
      this.cargando = true;
      axios.get("/api/tep/encuestasPreguntas").then((response) => {
        this.cargando = false;
        this.groups[0].items = response.data[0];
        this.groups[1].items = response.data[1];
      });
    },

    actConductores() {
      this.cargando = true;
      axios.get("/api/tep/encuestasPreguntas").then((response) => {
        this.cargando = false;
        this.groups[0].items = response.data[0];
      });
    },

    actPasajeros() {
      this.cargando = true;
      axios.get("/api/tep/encuestasPreguntas").then((response) => {
        this.cargando = false;
        this.groups[1].items = response.data[1];
      });
    },

    addQuestionConductor() {
      if (this.groups[0].items.length > 0) {
        this.groups[0].items.push({
          check: false,
          orden: this.groups[0].items.length,
          tipo_encuesta: 1,
          estado: 1,
        });
      } else {
        this.groups[0].items.push({
          check: false,
          tipo_encuesta: 1,
          orden: 0,
          estado: 1,
        });
      }
    },

    addQuestionPasajero() {
      if (this.groups[1].items.length > 0) {
        this.groups[1].items.push({
          check: false,
          orden: this.groups[1].items.length,
          tipo_encuesta: 2,
          estado: 1,
        });
      } else {
        this.groups[1].items.push({
          check: false,
          tipo_encuesta: 2,
          orden: 0,
          estado: 1,
        });
      }
    },

    async save(act) {
      this.cargando = true;
      let envio = [];
      let mensaje = "";
      if (act == 1) {
        envio = this.groups[0].items;
        mensaje = "Se actualizaron las preguntas para el conductor...";
      } else {
        envio = this.groups[1].items;
        mensaje = "Se actualizaron las preguntas para el pasajero...";
      }
      let datos = { datos: envio };
      axios({
        method: "POST",
        url: "/api/tep/encuestasPreguntas",
        data: datos,
      })
        .then(async (response) => {
          await this.getIndex();
          await this.$swal({
            icon: "success",
            title: mensaje,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: `Ha ocurrido un error: ${e.response.data.message}`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    changeConductores(evt) {
      for (let i = 0; i < evt.length; i++) {
        this.groups[0].items[i].orden = i;
      }
    },

    changePasajeros(evt) {
      for (let i = 0; i < evt.length; i++) {
        this.groups[1].items[i].orden = i;
      }
    },

    async limpiarConductores() {
      for (let i = 0; i < this.groups[0].items.length; i++) {
        if (this.groups[0].items[i].check == 1) {
          if (this.groups[0].items[i].id !== undefined) {
            this.$swal({
              title: "Está seguro de eliminar este conductor?",
              text: "Los cambios no se pueden revertir!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Eliminar!",
            }).then((result) => {
              if (result.value) {
                axios
                  .delete(
                    "/api/tep/encuestasPreguntas/" + this.groups[0].items[i].id
                  )
                  .then(() => {
                    this.getIndex();
                    this.$swal({
                      icon: "success",
                      title: "La pregunta se eliminó exitosamente...",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 4000,
                      timerProgressBar: true,
                    });
                  });
              }
            });
          } else {
            await this.groups[0].items.splice(i, 1);
          }
        }
      }
      for (let i = 0; i < this.groups[0].items.length; i++) {
        this.groups[0].items[i].orden = i;
      }
    },

    async limpiarPasajeros() {
      for (let i = 0; i < this.groups[1].items.length; i++) {
        if (this.groups[1].items[i].check == 1) {
          if (this.groups[1].items[i].id !== undefined) {
            this.$swal({
              title: "Está seguro de eliminar este pasajero?",
              text: "Los cambios no se pueden revertir!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Si, Eliminar!",
            }).then((result) => {
              if (result.value) {
                axios
                  .delete(
                    "/api/tep/encuestasPreguntas/" + this.groups[1].items[i].id
                  )
                  .then(() => {
                    this.getIndex();
                    this.$swal({
                      icon: "success",
                      title: "La pregunta se eliminó exitosamente...",
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 4000,
                      timerProgressBar: true,
                    });
                  });
              }
            });
          } else {
            await this.groups[1].items.splice(i, 1);
          }
        }

        for (let i = 0; i < this.groups[0].items.length; i++) {
          this.groups[1].items[i].orden = i;
        }
      }
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
<style lang="scss">
$ease-out: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
$to-do: #f4ce46;
$in-progress: #2a92bf;
$approved: #00b961;

.drag-list {
  display: flex;
  align-items: flex-start;
  font-size: 15px;

  @media (max-width: 800px) {
    display: none;
  }
}

.drag-column {
  flex: 1;
  margin: 0 10px;
  position: relative;
  background: rgba(black, 0.05);
  overflow: hidden;
  border-radius: 15px;

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px;
  user-select: none;
}

.drag-inner-list {
  height: 75vh;
  overflow: auto;
}

.drag-item {
  margin: 20px;
  height: 140px;
  background: rgba(black, 0.1);
  transition: $ease-out;
  border-radius: 15px;
  margin-left: auto;

  /* items grabbed state */
  &[aria-grabbed="true"] {
    background: linear-gradient(to bottom right, #ff1c79, #001871);
    color: #fff;
  }

  .drag-item-text {
    font-size: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
}

.drag-header-more {
  cursor: pointer;
}

@keyframes nodeInserted {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 0.6;
  }
}

.item-dropzone-area {
  height: 8rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 15px;
}
</style>